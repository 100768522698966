var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BASE_URL, POPUP_BASE_URL } from './constants';
import { beforeUnLoadFunction } from './tools';
var servername = 'https://api.checkbus.co.kr';
if (process.env.NODE_ENV == 'development') {
    servername = 'https://checktest.irontrain.co.kr';
    // servername = 'http://10.0.25.11'; // ! 실서버(크론)으로 테스트 필요시
}
function setHeader(uri, no_content_type) {
    var root_path = uri.split('/')[1];
    var check_arr = ['login'];
    var target_cookie = uri == '/login/auto' ? 'auto-token' : 'a-token';
    var cookie = getCookie(target_cookie) ? getCookie(target_cookie) : null;
    var header = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    if (no_content_type) {
        delete header['Content-Type'];
    }
    if (cookie) {
        header['Authorization'] = "Bearer ".concat(cookie);
    }
    return header;
}
function setToken(res) {
    var three_month_later = new Date(new Date().setMonth(new Date().getMonth() + 3));
    var one_month_later = new Date(new Date().setMonth(new Date().getMonth() + 1));
    if (res.accessToken) {
        document.cookie = "a-token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"; // 만료 코드
        document.cookie = "a-token=".concat(res.accessToken, "; expires=").concat(three_month_later, "; path=/"); // 업데이트 코드
    }
    if (res.newAccessToken) {
        document.cookie = "a-token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        document.cookie = "a-token=".concat(res.newAccessToken, "; expires=").concat(three_month_later, "; path=/");
    }
    if (res.autoToken) {
        document.cookie = "auto-token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        document.cookie = "auto-token=".concat(res.autoToken, "; expires=").concat(one_month_later, "; path=/");
    }
    return;
}
function checkExpiredError(res) {
    if (res.message == 'TokenExpiredError') {
        return true;
    }
    return false;
}
export function getCookie(type) {
    var cookie = document.cookie;
    if (cookie.length > 0) {
        var cookie_split = cookie.split(';');
        var cookie_arr = cookie_split.map(function (item) {
            var splited = item.split('=');
            return {
                target: splited[0].trim(),
                value: splited[1].trim(),
            };
        });
        var res_item = cookie_arr.findIndex(function (item) {
            return item.target == type;
        });
        if (cookie_arr[res_item]) {
            return cookie_arr[res_item].value;
        }
        else {
            return null;
        }
    }
    return null;
}
function getValidatedUri(uri) {
    var session_user = sessionStorage.getItem('user');
    if (session_user) {
        var user = JSON.parse(session_user);
        var splited_uri = uri.split('/').filter(function (item) { return item; });
        if (splited_uri[0] == '0' && splited_uri[2] == '0') {
            splited_uri[0] = user.cid;
            splited_uri[2] = user.uid;
            return "/".concat(splited_uri.join('/'));
        }
        else {
            return uri;
        }
    }
    return uri;
}
function setUri(uri) {
    var new_servername = servername;
    var new_uri = uri;
    if (uri.includes('{banner}')) {
        new_servername = POPUP_BASE_URL;
        new_uri = new_uri.replace(/{banner}/, '');
    }
    else if (uri.includes('{v1}')) {
        new_servername = POPUP_BASE_URL;
        new_uri = new_uri.replace(/{v1}/, '');
    }
    else if (uri.includes('{ims}')) {
        new_servername =
            process.env.NODE_ENV == 'production' ? 'https://super-api.irontrain.co.kr' : 'https://totapidev.irontrain.co.kr';
        new_uri = new_uri.replace(/{ims}/, '');
    }
    else if (uri.includes('{cf_errornotice}')) {
        new_servername = 'https://e49n33s5yk.apigw.ntruss.com/errornotice/v001/qMlXKqxEpY/json?service=cb';
        new_uri = new_uri.replace(/{cf_errornotice}/, '');
    }
    var session_user = window.sessionStorage.getItem('user');
    if (session_user) {
        var json_user = JSON.parse(session_user);
        if (!uri.includes('/account/send/pretest') &&
            !uri.includes('/academy/tutorial') &&
            !location.pathname.includes('/account/sms') &&
            location.pathname.includes('/account') &&
            json_user.memtype == 'F') {
            if (location.pathname.includes('/account') &&
                !uri.includes('teacher/all?sort=uclass') &&
                !uri.includes('account/details/testSend') &&
                !uri.includes('academy/info')) {
                if (process.env.NODE_ENV === 'production') {
                    new_uri = new_uri.replace("/".concat(json_user.cid, "/user/").concat(json_user.uid), '/21848/user/1371579');
                }
                else {
                    new_uri = new_uri.replace("/".concat(json_user.cid, "/user/").concat(json_user.uid), '/21848/user/1371579');
                }
            }
        }
    }
    return new_servername + getValidatedUri(new_uri);
}
function checkTokenError(res) {
    if ([403, 401].includes(res.status)) {
        window.removeEventListener('beforeunload', beforeUnLoadFunction);
        window.sessionStorage.removeItem('user');
        document.cookie = "a-token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"; // 만료 코드
        window.location.replace("".concat(BASE_URL, "/login?auto=false"));
        alert('세션이 종료되었습니다. 다시 로그인 바랍니다.');
    }
}
// Fetch POST
export var fetchPostApi = function (uri, args, use_vali) {
    return __awaiter(this, void 0, void 0, function () {
        var session_user, json_user, response, responseJson;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    session_user = window.sessionStorage.getItem('user');
                    if (session_user) {
                        json_user = JSON.parse(session_user);
                        if (json_user.pretest == 1) {
                            if (!uri.includes('/academy/tutorial') && !uri.includes('account/details/testSend')) {
                                if (uri.includes('remain')) {
                                    return [2 /*return*/, {
                                            pass: true,
                                            message: __assign(__assign({}, json_user), { smsok: {
                                                    data: [json_user.smsok],
                                                }, alimtalkuse: {
                                                    data: [json_user.alimtalkuse],
                                                }, attendance_sms_abs_msg: "".concat(json_user.attendance_sms_title, "\r\n").concat(json_user.attendance_sms_abs_msg), attendance_sms_att_msg: "".concat(json_user.attendance_sms_title, "\r\n").concat(json_user.attendance_sms_att_msg) }),
                                        }];
                                }
                                else if (!uri.includes('/account/send/pretest')) {
                                    return [2 /*return*/, {
                                            pass: true,
                                            message: [{ rname: '체험 전용', rid: 1 }],
                                        }];
                                }
                            }
                        }
                    }
                    return [4 /*yield*/, fetch(setUri(uri), {
                            method: 'POST',
                            headers: __assign({}, setHeader(uri)),
                            body: JSON.stringify(args),
                        })];
                case 1:
                    response = _a.sent();
                    checkTokenError(response);
                    return [4 /*yield*/, response.json()];
                case 2:
                    responseJson = _a.sent();
                    setToken(responseJson);
                    return [2 /*return*/, responseJson];
            }
        });
    });
};
// Fetch GET
export var fetchGetApi = function (uri) {
    return __awaiter(this, void 0, void 0, function () {
        var response, responseJson, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch(setUri(uri), {
                            method: 'GET',
                            headers: __assign({}, setHeader(uri)),
                        })];
                case 1:
                    response = _a.sent();
                    checkTokenError(response);
                    return [4 /*yield*/, response.json()];
                case 2:
                    responseJson = _a.sent();
                    setToken(responseJson);
                    return [2 /*return*/, responseJson];
                case 3:
                    err_1 = _a.sent();
                    console.log(err_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
};
export var fetchWithTimeout = function (uri, options) {
    return __awaiter(this, void 0, void 0, function () {
        var timeout, controller_1, id, response, responseJson, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    timeout = 2000;
                    if (options === null || options === void 0 ? void 0 : options.timeout) {
                        timeout = options.timeout;
                    }
                    controller_1 = new AbortController();
                    id = setTimeout(function () { return controller_1.abort(); }, timeout);
                    return [4 /*yield*/, fetch(setUri(uri), __assign(__assign({}, options), { signal: controller_1.signal }))];
                case 1:
                    response = _a.sent();
                    clearTimeout(id);
                    return [4 /*yield*/, response.json()];
                case 2:
                    responseJson = _a.sent();
                    return [2 /*return*/, responseJson];
                case 3:
                    err_2 = _a.sent();
                    console.log(err_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
};
// Fetch POST FILES
export var fetchFileApi = function (uri, args) {
    return __awaiter(this, void 0, void 0, function () {
        var response, responseJson;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch(setUri(uri), {
                        method: 'POST',
                        headers: __assign({}, setHeader(uri, true)),
                        body: args,
                    })];
                case 1:
                    response = _a.sent();
                    checkTokenError(response);
                    return [4 /*yield*/, response.json()];
                case 2:
                    responseJson = _a.sent();
                    return [2 /*return*/, responseJson];
            }
        });
    });
};
