// 크기 정할 때 사용하는 함수 -> 0 부터 1까지의 값을 넣어서 % 값 리턴
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { account_sms_default } from './account_sms_contents';
import { fetchGetApi } from './api';
import { getKorUclass } from './user_tools';
import { lazy } from 'react';
import { drop_contents_start, drop_contents_end } from './attendance_sms_dropdown_setting';
import { BASE_URL, TableTypeKeys, TableDefaultSortKey } from './constants';
import dayjs from 'dayjs';
// 1보다 큰 수일 경우 해당 값 리턴 -> 해당값은 px로 MUI에서 인식
export function transform(value) {
    return value <= 1 ? "".concat(value * 100, "%") : value;
}
// 수업 날짜 한글로 출력
export function setStudyDayText(nums) {
    var days = ['일', '월', '화', '수', '목', '금', '토'];
    var res = [];
    var tmp_nums = nums;
    var zero_idx = tmp_nums.indexOf(0);
    if (zero_idx >= 0) {
        tmp_nums.splice(zero_idx, 1);
        tmp_nums.push(0);
    }
    for (var _i = 0, tmp_nums_1 = tmp_nums; _i < tmp_nums_1.length; _i++) {
        var x = tmp_nums_1[_i];
        res.push(days[x]);
    }
    return res.join(', ');
}
// 0000년 00월 00일
export function setLocaleDateString(date, type) {
    var new_date;
    if (date !== null) {
        new_date = date;
    }
    else {
        new_date = new Date();
    }
    var y = new_date.getFullYear();
    var m = new_date.getMonth() + 1;
    var d = new_date.getDate();
    var res = '';
    if (type == 'kor') {
        res = "".concat(y, "\uB144 ").concat(Number(m) < 10 ? '0' + m : m, "\uC6D4 ").concat(Number(d) < 10 ? '0' + d : d, "\uC77C");
    }
    else {
        res = "".concat(y).concat(type).concat(Number(m) < 10 ? '0' + m : m).concat(type).concat(Number(d) < 10 ? '0' + d : d);
    }
    return res;
}
// 날짜 구하기
export function setAgoDate(type, gap, date) {
    var d = new Date();
    var start_date = d;
    var end_date = d;
    if (type == 'year') {
        start_date = new Date("".concat(new Date().getFullYear() + gap, "-1-1"));
        if (start_date.getFullYear() == new Date().getFullYear()) {
            end_date = new Date();
        }
        else {
            end_date = new Date("".concat(start_date.getFullYear(), "-12-31"));
        }
    }
    else if (type == 'month') {
        start_date = new Date(new Date(new Date().setMonth(new Date().getMonth() + gap)).setDate(1));
        if (start_date.getMonth() == new Date().getMonth()) {
            end_date = new Date();
        }
        else {
            end_date = new Date(new Date(new Date().setMonth(new Date().getMonth() + gap + 1)).setDate(0));
        }
    }
    else if (type == 'week') {
        start_date = new Date(new Date().setDate(new Date().getDate() + gap * 7));
        end_date = new Date(new Date().setDate(new Date().getDate() + gap * 7 + 7));
    }
    else if (type == 'date') {
        start_date = new Date(new Date().setDate(new Date().getDate() + gap));
        end_date = start_date;
    }
    return { start_date: start_date, end_date: end_date };
}
// 테스트
// 등록 버튼 등 disabled 상태 관리
export function setBtnDisabled(data, type) {
    var require_num = 0;
    var comp_require_num = 0;
    var edit_num = 0;
    var edit_keys = [];
    for (var _i = 0, _a = Object.entries(data); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], val = _b[1];
        if (val.require)
            require_num++;
        if (val.require && val.value.length > 0)
            comp_require_num++;
        if ((type == 'update' || type == 'edit') && val.origin != val.value) {
            edit_num++;
            edit_keys.push(key);
        }
        else {
            if (key == 'charge_date' && val.value_info) {
                edit_num++;
                edit_keys.push(key);
            }
        }
    }
    if (require_num == comp_require_num) {
        if (type == 'update' || type == 'edit') {
            if (edit_num > 0) {
                return { result: false, keys: edit_keys };
            }
            else {
                return { result: true, keys: [] };
            }
        }
        else {
            return { result: false, keys: edit_keys };
        }
    }
    return { result: true, keys: [] };
}
export function makeListUri(start, queries) {
    var target = queries.target, sort = queries.sort, page = queries.page, limit = queries.limit, search_keyword = queries.search_keyword;
    var items = [];
    if (target)
        items.push("sort=".concat(target));
    if (sort)
        items.push("sortval=".concat(sort));
    if (page)
        items.push("page=".concat(page));
    if (limit)
        items.push("limit=".concat(limit));
    if (search_keyword)
        items.push("search_keyword=".concat(escapeSpecialCharacter(search_keyword)));
    // 현재 필요x (일단 남겨둠)
    var uri = start + '?' + items.join('&');
    return uri;
}
var paths = {
    student: {
        teacher: {
            edit: {
                edit2: {},
            },
        },
        class: {
            edit: {},
        },
        list: {
            edit: {},
        },
        registration: {},
        counsel: {},
        study: {
            edit: {},
        },
    },
    attendance: {
        status: {},
        check: {},
        manager: {},
        sms: {},
        covid: {},
    },
    account: {
        check: {},
        manage: {},
        create: {},
        statistics: {},
        calculate: {},
        sms: {},
        download: {},
    },
    sms: {
        send: {},
        detail: {},
        reservation: {},
    },
    setup: {
        info: {},
        phone: {},
        receipt: {},
        check: {},
        service: {},
        product: {},
    },
    cash: {
        charge: {},
        'charge-detail': {},
        'use-detail': {},
    },
    covid: {
        record: {},
    },
    magazine: {},
};
export function validationPath(currentUrl) {
    var current_url_array = currentUrl.split("".concat(BASE_URL, "/"));
    var current_url_depth = current_url_array.length - 1;
    if (currentUrl === '/')
        return true;
    var target = {};
    for (var idx = 1; idx <= current_url_depth; idx++) {
        if (idx == 1) {
            target = paths[current_url_array[idx]];
        }
        else {
            target = target[current_url_array[idx]];
        }
    }
    if (target) {
        return true;
    }
    else {
        return false;
    }
}
//
// 머문시간 구하는 함수 (stay_time)
export function calculateStayTime(start_time, end_time) {
    var start_time_array = start_time.split(':');
    var end_time_array = end_time.split(':');
    // 정규식 이용해 먼저 시간인지 확인 아니면 바로 '-'
    var time_format = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
    if (time_format.test(start_time) && time_format.test(end_time)) {
        if (start_time_array.length === 2 && end_time_array.length === 2) {
            if (Number(start_time_array[0]) > Number(end_time_array[0]) ||
                (Number(start_time_array[0]) === Number(end_time_array[0]) &&
                    Number(start_time_array[1]) > Number(end_time_array[1]))) {
                return '-';
            }
            else {
                var stay_time_array = end_time_array.map(function (item, index) { return Number(end_time_array[index]) - Number(start_time_array[index]); });
                if (stay_time_array[1] < 0) {
                    stay_time_array[0] = stay_time_array[0] - 1;
                    if (stay_time_array[0] === 0)
                        return "".concat(60 + stay_time_array[1], "\uBD84");
                    else
                        return "".concat(stay_time_array[0], "\uC2DC\uAC04 ").concat(60 + stay_time_array[1], "\uBD84");
                }
                else if (stay_time_array[1] === 0) {
                    if (stay_time_array[0] === 0)
                        return "".concat(stay_time_array[1], "\uBD84");
                    else
                        return "".concat(stay_time_array[0], "\uC2DC\uAC04");
                }
                else {
                    if (stay_time_array[0] === 0)
                        return "".concat(stay_time_array[1], "\uBD84");
                    else
                        return "".concat(stay_time_array[0], "\uC2DC\uAC04 ").concat(stay_time_array[1], "\uBD84");
                }
            }
        }
        else {
            return '-';
        }
    }
    else {
        return '-';
    }
}
// modal_confrim title 가변적으로 만드는 함수
export function makeDateTitle(date_array) {
    return "".concat(date_array[1], "\uC6D4 ").concat(date_array[2], "\uC77C \uCD9C\uACB0 \uD604\uD669\uC744 \uC870\uD68C\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?");
}
export function makeModalSmsTarget(id, default_type) {
    var target = '';
    switch (true) {
        case [1].includes(id):
            target = '등원 ';
            if (default_type === 'S')
                target += '메시지가';
            else
                target += '알림톡이';
            break;
        case [2].includes(id):
            target = '하원';
            if (default_type === 'S')
                target += '메시지가';
            else
                target += '알림톡이';
            break;
        case [3].includes(id):
            target = '지각';
            if (default_type === 'S')
                target += '메시지가';
            else
                target += '알림톡이';
            break;
        case [4].includes(id):
            target = '결석';
            if (default_type === 'S')
                target += '메시지가';
            else
                target += '알림톡이';
            break;
        case [5].includes(id):
            target = '일괄 등원처리';
            break;
        case [6].includes(id):
            target = '일괄 하원처리';
            break;
        case [7].includes(id):
            target = '일괄 지각처리';
            break;
        case [8].includes(id):
            target = '일괄 결석처리';
            break;
    }
    return target;
}
export function makeHourMinute() {
    var today = new Date();
    var hours = today.getHours();
    var minutes = today.getMinutes();
    if (hours < 10) {
        if (minutes < 10)
            return '0' + hours + ':' + '0' + minutes;
        else
            return '0' + hours + ':' + minutes;
    }
    else {
        if (minutes < 10)
            return hours + ':' + '0' + minutes;
        else
            return hours + ':' + minutes;
    }
}
export function getAfterMonth(date, resverse) {
    var date_array = date.split('-');
    var req_year = Number(date_array[0]);
    var req_month = Number(date_array[1]);
    var this_year = new Date().getFullYear();
    var this_month = new Date().getMonth() + 1;
    var res_arr = [];
    if (resverse) {
        for (var i = this_year, leng = req_year; i >= leng; i--) {
            var target_month = req_month;
            var target_leng = 12;
            if (i != req_year) {
                target_month = 1;
            }
            if (i == this_year) {
                target_leng = this_month;
            }
            for (var j = target_leng; j >= target_month; j--) {
                res_arr.push("".concat(i, "\uB144 ").concat(j < 10 ? '0' + j : j, "\uC6D4"));
            }
        }
    }
    else {
        for (var i = req_year, leng = this_year; i <= leng; i++) {
            var target_month = req_month;
            var target_leng = 12;
            if (i != req_year) {
                target_month = 1;
            }
            if (i == this_year) {
                target_leng = this_month;
            }
            for (var j = target_month; j <= target_leng; j++) {
                res_arr.push("".concat(i, "\uB144 ").concat(j < 10 ? '0' + j : j, "\uC6D4"));
            }
        }
    }
    return res_arr;
}
export function makeMonthDateDay(date) {
    var date_array = date.split('-');
    date_array.splice(0, 1);
    var month = date_array[0][0] === '0' ? date_array[0][1] : date_array[0];
    var day = date_array[1][0] === '0' ? date_array[1][1] : date_array[1];
    var week = ['일', '월', '화', '수', '목', '금', '토'];
    var dayOfWeek = week[new Date(date).getDay()]; // 요일
    return "".concat(month, "\uC6D4 ").concat(day, "\uC77C (").concat(dayOfWeek, ")");
}
export function getIdRCheckday(rcheckday) {
    var rcheckday_arr = rcheckday.split(', ').filter(function (item) { return item; });
    var days = {
        일: '0',
        월: '1',
        화: '2',
        수: '3',
        목: '4',
        금: '5',
        토: '6',
    };
    return rcheckday_arr.map(function (item) { return days[item]; }).toString();
}
export function makePhoneNumber(cur_phone_number) {
    var phone_number = "".concat(cur_phone_number).trim();
    if (phone_number.includes('-'))
        phone_number = phone_number.split('-').join('');
    var condition = phone_number.length === 10;
    if (phone_number.substring(0, 2) === '02') {
        var first_digit = phone_number.substring(0, 2);
        var second_digit = condition ? phone_number.substring(2, 6) : phone_number.substring(2, 5);
        var third_digit = condition ? phone_number.substring(6) : phone_number.substring(5);
        return "".concat(first_digit, "-").concat(second_digit, "-").concat(third_digit);
    }
    else if (cur_phone_number.length === 8) {
        return "".concat(phone_number.substring(0, 4), "-").concat(phone_number.substring(4, 8));
    }
    else {
        var first_digit = phone_number.substring(0, 3);
        var second_digit = condition ? phone_number.substring(3, 6) : phone_number.substring(3, 7);
        var third_digit = condition ? phone_number.substring(6) : phone_number.substring(7);
        return "".concat(first_digit, "-").concat(second_digit, "-").concat(third_digit);
    }
}
export function autoHypenPhone(str) {
    str = str.replace(/[^0-9]/g, '');
    var tmp = '';
    if (str.length < 4) {
        return str;
    }
    else if (str.length < 7) {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3);
        return tmp;
    }
    else if (str.length < 11) {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 3);
        tmp += '-';
        tmp += str.substr(6);
        return tmp;
    }
    else {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 4);
        tmp += '-';
        tmp += str.substr(7);
        return tmp;
    }
}
export function setAccountNumber(account_number) {
    return account_number.replace(/^(\d{3})(\d{6})(\d{2})(\d{3})$/, "$1-$2-$3-$4");
}
export function setDateFormat(current_date) {
    return current_date.replace(/^(\d{4})(\d{2})(\d{2})$/, "$1-$2-$3");
}
export function setBusinessNumber(business_number) {
    return business_number
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{0,3})(\d{0,2})(\d{0,5})$/g, '$1-$2-$3')
        .replace(/(\-{1,2})$/g, '');
}
export function makeMinuteContents() {
    var minute_array = [];
    var id = 0;
    for (var i = 0; i <= 59; i++) {
        if (i % 5 != 0)
            continue;
        var obj = { id: 0, label: '' };
        obj.id = id;
        if (i < 10)
            obj.label = "0".concat(i, "\uBD84");
        else
            obj.label = "".concat(i, "\uBD84");
        minute_array.push(obj);
        id++;
    }
    return minute_array;
}
export function makeHourContents() {
    var hour_array = [];
    for (var i = 0; i < 24; i++) {
        var obj = { id: 0, label: '' };
        obj.id = i;
        if (i < 10) {
            obj.label = "0".concat(i, "\uC2DC");
        }
        else {
            obj.label = "".concat(i, "\uC2DC");
        }
        hour_array.push(obj);
    }
    return hour_array;
}
export function checkTableQueries(table_query) {
    var target = table_query.target, sort = table_query.sort, page = table_query.page, max = table_query.max;
    if (target == '' && sort == '' && page == 1 && max == getListNum()) {
        return false;
    }
    else {
        return true;
    }
}
export function setPhoneFormat(phone) {
    var cur_phone = "".concat(phone).trim();
    if (cur_phone.slice(0, 2) == '02') {
        return "".concat(cur_phone).replace(/^(\d{2})(\d{3,4})(\d{4})$/, "$1-$2-$3");
    }
    else {
        return "".concat(cur_phone).replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, "$1-$2-$3");
    }
}
export function setDateHypen(phone) {
    var cur_phone = "".concat(phone).trim();
    return "".concat(cur_phone).replace(/^(\d{4})(\d{2})(\d{2})$/, "$1-$2-$3");
}
export function makeYearMonth(date) {
    var date_array = date.split('-');
    return "".concat(date_array[0], "\uB144 ").concat(date_array[1], "\uC6D4");
}
export function getByte(str) {
    var msg = str.replace(/\r\n/g, '\n');
    msg = msg.replace(/{학생명}/g, '홍길동');
    msg = msg.replace(/{이름}/g, '홍길동');
    msg = msg.replace(/{시간}/g, 'OO시 OO분');
    msg = msg.replace(/\n/g, '.');
    return msg
        .split('')
        .map(function (s) { return s.charCodeAt(0); })
        .reduce(function (prev, c) { return prev + (c === 10 ? 2 : c >> 7 ? 2 : 1); }, 0);
}
export function getYearArray(date) {
    var year_arr = [];
    var start_year = +date.split('-')[0];
    for (var i = new Date().getFullYear(); i >= start_year; i--) {
        year_arr.push(String(i) + '년');
    }
    return year_arr;
}
export function getMonthArray(date, year) {
    var month_arr = [];
    var max_month;
    var start_month;
    var start_year = +date.split('-')[0];
    var current_year = +year;
    if (current_year == start_year) {
        start_month = +date.split('-')[1];
        if (current_year == new Date().getFullYear()) {
            max_month = new Date().getMonth() + 1;
            for (var i = max_month; i >= start_month; i--) {
                month_arr.push("".concat(i < 10 ? '0' + i + '월' : i + '월'));
            }
        }
        else {
            for (var i = 12; i >= start_month; i--) {
                month_arr.push("".concat(i < 10 ? '0' + i + '월' : i + '월'));
            }
        }
    }
    else {
        if (current_year == new Date().getFullYear())
            max_month = new Date().getMonth() + 1;
        else
            max_month = 12;
        for (var i = max_month; i >= 1; i--) {
            month_arr.push("".concat(i < 10 ? '0' + i + '월' : i + '월'));
        }
    }
    return month_arr;
}
export function comparePostDate(class_post_date, student_post_date) {
    var class_year = +class_post_date.split('-')[0];
    var student_year = +student_post_date.split('-')[0];
    if (class_year < student_year) {
        return true;
    }
    else if (class_year === student_year) {
        var class_month = +class_post_date.split('-')[1];
        var student_month = +student_post_date.split('-')[1];
        if (class_month < student_month) {
            return true;
        }
        else if (class_month === student_month) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
    // true면 클래스가 빠른거
    // false면 학생이 빠른거
    // 월까지 비교 후 같으면 학생의 post_date 기준으로 세팅
}
export function getDivisionKor(division) {
    var res = '';
    if (division == 'direct') {
        res = '수동';
    }
    else if (division == 'attendance') {
        res = '출결';
    }
    else if (division == 'temperature') {
        res = '체온';
    }
    else if (division == 'account') {
        res = '수납';
    }
    else if (division == 'counsel') {
        res = '상담';
    }
    else {
        res = '기타';
    }
    return res;
}
export function sortingBirthday(birth_list) {
    //
}
export function makeBirthdayItem(birth_item) {
    //
}
export function makeSmsDetailButtonDate(gap) {
    var start;
    var end;
    var d = new Date(); // test 인자
    var after_one_date = 1000 * 60 * 60 * 24;
    var lastDayofLastMonth = new Date(d.getFullYear(), d.getMonth() - gap, 0).getDate();
    var lastDayofNowMonth = new Date(d.getFullYear(), d.getMonth() - gap + 1, 0).getDate();
    if (d.getDate() === new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate()) {
        var d_1 = new Date(); // 말일만 test 인자
        d_1.setDate(1);
        d_1.setMonth(d_1.getMonth() - gap);
        var start_date = new Date(d_1);
        d_1.setDate(lastDayofNowMonth);
        var end_date = new Date(d_1);
        start = setLocaleDateString(start_date, '-');
        end = setLocaleDateString(end_date, '-');
    }
    else {
        var d_2 = new Date(); // test 인자
        if (lastDayofNowMonth > lastDayofLastMonth && d_2.getDate() >= lastDayofLastMonth) {
            d_2.setDate(lastDayofLastMonth);
            d_2.setMonth(d_2.getMonth() - gap - 1);
            var start_date = new Date(new Date(d_2).getTime() + after_one_date);
            d_2 = new Date(); // test 인자
            d_2.setDate(d_2.getDate());
            d_2.setMonth(d_2.getMonth() - gap);
            var end_date = new Date(d_2);
            start = setLocaleDateString(start_date, '-');
            end = setLocaleDateString(end_date, '-');
        }
        else {
            d_2.setDate(d_2.getDate());
            d_2.setMonth(d_2.getMonth() - gap - 1);
            var start_date = new Date(new Date(d_2).getTime() + after_one_date);
            if (lastDayofNowMonth == 28) {
                if (d_2.getDate() >= lastDayofNowMonth) {
                    d_2.setDate(lastDayofNowMonth);
                }
            }
            d_2.setMonth(d_2.getMonth() + 1);
            var end_date = new Date(new Date(d_2));
            start = setLocaleDateString(start_date, '-');
            end = setLocaleDateString(end_date, '-');
        }
    }
    return { start: start, end: end };
}
export function makeFontSize(font_size_obj, default_font_size, property, font) {
    var new_font_size = '';
    var font_obj = {};
    for (var font_size in font_size_obj) {
        if (font_size_obj[font_size]) {
            new_font_size = font_size;
            break;
        }
    }
    if (new_font_size == 'default') {
        font_obj[property] = default_font_size + "".concat(font ? 'rem' : '%');
    }
    else if (new_font_size == 'small') {
        font_obj[property] = default_font_size * 0.8 + "".concat(font ? 'rem' : '%');
    }
    else {
        font_obj[property] = default_font_size * 1.2 + "".concat(font ? 'rem' : '%');
    }
    return font_obj;
}
export function getDateDiff(day) {
    var date1 = new Date();
    var date2 = new Date(day);
    var diffDate = date1.getTime() - date2.getTime();
    return "".concat(Math.ceil(diffDate / (1000 * 60 * 60 * 24)), "\uC77C");
}
export function getTotalStudyCount(dayArray, year, month) {
    var count = 0;
    var total_date = new Date(year, month, 0).getDate();
    for (var i = 1; i <= total_date; i++) {
        if (dayArray.includes(String(new Date(year, month, i).getDay())))
            count++;
    }
    return "(\uC804\uCCB4 ".concat(count, "\uC77C)");
}
export function getListNum() {
    return JSON.parse("".concat(window.localStorage.getItem('list_num')))[location.pathname];
}
export function getLeftMessageCount(smsCnt, isTable) {
    if (smsCnt >= 0)
        return "".concat(makeCommaAsThousandSeparator(smsCnt), "\uAC74");
    else {
        if (isTable)
            return {
                type: 'color-#ff382a',
                label: "".concat(makeCommaAsThousandSeparator(Math.abs(smsCnt)), "\uAC74 \uCD08\uACFC"),
            };
        else
            return "".concat(makeCommaAsThousandSeparator(Math.abs(smsCnt)), "\uAC74 \uCD08\uACFC");
    }
}
export var setLoginUserInfo = function (info) {
    var sms_att_msg_splited = info.attendance_sms_att_msg ? info.attendance_sms_att_msg.split('\r\n') : null;
    var sms_abs_msg_splited = info.attendance_sms_abs_msg ? info.attendance_sms_abs_msg.split('\r\n') : null;
    var sms_att_msg = sms_att_msg_splited
        ? sms_att_msg_splited.filter(function (item, item_idx) { return item_idx != 0; }).join('')
        : null;
    var sms_abs_msg = sms_abs_msg_splited
        ? sms_abs_msg_splited.filter(function (item, item_idx) { return item_idx != 0; }).join('')
        : null;
    var account_sms_msg = !info.account_sms ? account_sms_default : info.account_sms_msg;
    var mobile_exp = new RegExp('Android|webOS|iPhone|iPad|' + 'BlackBerry|Windows Phone|' + 'Opera Mini|IEMobile|Mobile', 'i');
    var is_mobile = false;
    if (navigator && navigator.userAgent && mobile_exp.test(navigator.userAgent)) {
        is_mobile = true;
    }
    var notice_lms = true;
    if (info.transfer_date && info.alimtalkuse && info.alimtalkuse.data && info.alimtalkuse.data[0] < 2) {
        var test_title = '';
        var test_att = '';
        var test_abs = '';
        if (info.attendance_sms_att_msg && info.attendance_sms_abs_msg && sms_att_msg_splited) {
            test_title = sms_att_msg_splited[0];
            test_att = info.attendance_sms_att_msg;
            test_abs = info.attendance_sms_abs_msg;
        }
        else {
            test_title = "".concat(info.cname, " \uB4F1\uD558\uC6D0 \uC548\uB0B4");
            test_att = drop_contents_start[0].label.replace(/OOO/gi, '홍길동').replace(/OO시 OO분/gi, '12시 00분');
            test_abs = drop_contents_end[0].label.replace(/OOO/gi, '홍길동').replace(/OO시 OO분/gi, '12시 00분');
            test_att = "".concat(test_title, "\r\n").concat(test_att);
            test_abs = "".concat(test_title, "\r\n").concat(test_abs);
        }
        if (getByte(test_att) > 80 || getByte(test_abs) > 80 || test_title.length > 18) {
            notice_lms = false;
        }
    }
    var table_keys = Object.keys(TableTypeKeys).filter(function (key) { return isNaN(Number(key)); });
    var tableSort = {};
    table_keys.forEach(function (table_key) {
        var sortKey = null;
        var sortValue = null;
        var table_sortingValue = info[table_key];
        if (table_sortingValue && typeof table_sortingValue == 'string') {
            var split_value = table_sortingValue.split('::');
            sortKey = split_value[0];
            sortValue = split_value[1];
        }
        tableSort[table_key] = {
            sortKey: sortKey,
            sortValue: sortValue,
        };
    });
    var payload = {
        uid: info.uid,
        cid: info.cid,
        uclass: info.uclass,
        uname: info.uname,
        alimtalkuse: info.alimtalkuse.data[0],
        appreviewdone: info.appreviewdone,
        bepaid: info.bepaid,
        checkmoney: info.checkmoney,
        memtype: info.memtype,
        postdate: info.postdate,
        smscnt: info.smscnt,
        smsok: info.smsok.data[0],
        smsstop: info.smsstop,
        ubirth: info.ubirth,
        uloginid: info.uloginid,
        uphone: info.uphone,
        attendancesms: info.attendancesms,
        cname: info.cname,
        pass: true,
        phone: info.phone,
        cell: info.cell,
        attendance_alim_abs: info.attendance_alim_abs,
        attendance_alim_att: info.attendance_alim_att,
        attendance_alim_title: info.attendance_alim_title,
        attendance_sms_abs: info.attendance_sms_abs,
        attendance_sms_att: info.attendance_sms_att,
        account_alim_contents: info.account_alim_contents,
        account_alim_costday: info.account_alim_costday,
        account_alim_direct: info.account_alim_direct,
        account_alim_direct_msg: info.account_alim_direct_msg,
        account_first_time: info.account_first_time,
        account_second_date: info.account_second_date,
        account_second_time: info.account_second_time,
        account_second_notice: info.account_second_notice,
        use_attendance_msg: info.use_attendance_msg,
        attendance_sms_abs_msg: sms_abs_msg !== null && sms_abs_msg !== void 0 ? sms_abs_msg : '',
        attendance_sms_att_msg: sms_att_msg !== null && sms_att_msg !== void 0 ? sms_att_msg : '',
        attendance_sms_title: sms_att_msg_splited ? sms_att_msg_splited[0] : null,
        avaouttime: info.avaouttime,
        stdtime: info.stdtime,
        attendancemulti: info.attendancemulti,
        usehourformat: info.usehourformat,
        president: info.president,
        logofile: info.logofile,
        logofile_type: info.logofile_type,
        profile: info.profile,
        profile_type: info.profile_type,
        smsphone: info.smsphone,
        sms_err_status: info.sms_err_status,
        smsstartday: info.smsstartday,
        is_teacher: false,
        autosms: info.autosms,
        whenautosms: info.whenautosms,
        autosmscontent: info.autosmscontent,
        restime: info.restime,
        pwdate: info.pwdate,
        account_sms: info.account_sms,
        account_sms_msg: account_sms_msg,
        product_change_status: info.product_change_status,
        free_end_date: setLocaleDateString(new Date(new Date(info.postdate).setTime(new Date(info.postdate).getTime() + 1000 * 60 * 60 * 24 * 7)), '-'),
        current_list_num: 0,
        login: 1,
        pretest: ['pretest'].includes(info.uloginid) ? 1 : 0,
        transfer_date: info.transfer_date,
        is_mobile: is_mobile,
        notice_lms: notice_lms,
        account_registration: info.account_registration,
        sp_pkid: info.sp_pkid,
        cost: info.cost,
        payment: info.payment,
        notice_payment: false,
        // 수납관리 (사업자 정보)
        account_phone: info.account_phone,
        account_number: info.account_number,
        business_number: info.business_number,
        ceo_name: info.ceo_name,
        customer: info.customer,
        bank: info.bank,
        // v2이면서 수납관리 사용자
        useaccount: info.useaccount,
        open_account: false,
        // 지출증빙 정보 미기입시 팝업 여부 (0: 안보이게, 1: 보이도록)
        notice_envidence: info.notice_envidence,
        open: info.open,
        account_before: info.account_before,
        account_before_date: info.account_before_date,
        account_before_time: info.account_before_time,
        mallkind: '',
        account_expire: info.account_expire,
        account_expire_time: info.account_expire_time,
        spam_notice: false,
        tableSort: tableSort,
        // 실명인증 여부 (계정)
        // isCerti: info.isCerti,
        isRequestUser: info.isRequestUser,
        // 화면 zoom 상태
        zoom_status: info.zoom_status,
    };
    return payload;
};
export var sortingRcheckday = function (rcheckday) {
    var weekday = ['월', '화', '수', '목', '금', '토', '일'];
    var rcheckday_arr = rcheckday.split(',').map(function (day, idx) { return weekday.findIndex(function (element) { return element == day; }); });
    rcheckday_arr.sort(function (a, b) { return a - b; });
    return rcheckday_arr.map(function (day, idx) { return weekday[day]; }).join(', ');
};
export var validateKorean = function (value) {
    var koreanPattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    if (koreanPattern.test(value)) {
        return true;
    }
    else {
        return false;
    }
};
export var validatePassword = function (value) {
    var status = false;
    status = validateKorean(value);
    if (status)
        return false;
    else {
        var passwordPattern = /^[a-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]*$/;
        status = passwordPattern.test(value);
        if (status) {
            status = false;
            var p_num = value.search(/[0-9]/g);
            var p_spc = value.search(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]+/g);
            var p_alp = value.search(/[a-z]/g);
            if (p_num > -1 && p_spc > -1)
                status = true;
            if (p_num > -1 && p_alp > -1)
                status = true;
            if (p_spc > -1 && p_alp > -1)
                status = true;
            if (p_num && p_spc > -1 && p_alp > -1)
                status = true;
            return status;
        }
        else {
            return status;
        }
    }
};
export var makeCommaAsThousandSeparator = function (value) {
    return value.toLocaleString('ko-KR');
};
export var addClassInUsers = function (userInfo, item) { return __awaiter(void 0, void 0, void 0, function () {
    var rid, res, rows, i, leng, status_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(item && item.rid && item.details && !item.details.students)) return [3 /*break*/, 2];
                rid = item.rid;
                return [4 /*yield*/, fetchGetApi("/".concat(userInfo.cid, "/user/").concat(userInfo.uid, "/class/").concat(rid, "/inusers"))];
            case 1:
                res = _a.sent();
                if (res.message != 'null') {
                    rows = res.message.rows;
                    for (i = 0, leng = rows.length; i < leng; i++) {
                        status_1 = '';
                        if (rows[i].uclass != 'S') {
                            status_1 = "(".concat(getKorUclass(rows[i].uclass).label, ")");
                        }
                        if (i > 0)
                            item.details["s".concat(i)] = "".concat(rows[i].uname, " ").concat(status_1);
                        else
                            item.details.students = "".concat(rows[i].uname, " ").concat(status_1);
                    }
                }
                else {
                    item.details.students = '등록된 학생이 없습니다.';
                }
                _a.label = 2;
            case 2: return [2 /*return*/];
        }
    });
}); };
export var addClassInTeacher = function (userInfo, item) { return __awaiter(void 0, void 0, void 0, function () {
    var uid, res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(item && item.uid && item.details && item.details.rname === null)) return [3 /*break*/, 2];
                uid = item.uid;
                return [4 /*yield*/, fetchGetApi("/".concat(userInfo.cid, "/user/").concat(userInfo.uid, "/teacher/").concat(uid, "/rooms"))];
            case 1:
                res = _a.sent();
                if (res.message.rname) {
                    item.details.rname = res.message.rname;
                }
                else {
                    item.details.rname = '배정된 클래스가 없습니다.';
                }
                if (res.message.multi_rname) {
                    item.details.multi_rname = res.message.multi_rname;
                }
                _a.label = 2;
            case 2: return [2 /*return*/];
        }
    });
}); };
export var emptyCache = function () {
    // if ('caches' in window) {
    //   caches.keys().then(names => {
    //     // Delete all the cache files
    //     names.forEach(name => {
    //       caches.delete(name);
    //     });
    //   });
    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.removeEventListener('beforeunload', beforeUnLoadFunction);
    window.location.reload();
    // }
};
export var beforeUnLoadFunction = function (event) {
    event.preventDefault();
    event.returnValue = '';
};
export var makeAreaChartHour = function (time) {
    var hour = '';
    var string_time = String(time);
    if (string_time.length > 3) {
        hour = string_time.substring(0, 2);
    }
    else {
        hour = '0' + string_time.substring(0, 1);
    }
    return hour;
};
export var makeAreaChartMinute = function (time) {
    var minute = '';
    var string_time = String(time);
    minute = string_time.substring(2, 4);
    return minute;
};
export var makeDatetime = function (dateTime) {
    var year = dateTime.getFullYear();
    var month = dateTime.getMonth() + 1 < 10 ? "0".concat(dateTime.getMonth() + 1) : dateTime.getMonth() + 1;
    var date = dateTime.getDate() < 10 ? "0".concat(dateTime.getDate()) : dateTime.getDate();
    var hour = dateTime.getHours() < 10 ? "0".concat(dateTime.getHours()) : dateTime.getHours();
    var minute = dateTime.getMinutes() < 10 ? "0".concat(dateTime.getMinutes()) : dateTime.getMinutes();
    var second = dateTime.getSeconds() < 10 ? "0".concat(dateTime.getSeconds()) : dateTime.getSeconds();
    return "".concat(year, "-").concat(month, "-").concat(date, " ").concat(hour, ":").concat(minute, ":").concat(second);
};
export var makeExpirationDatetime = function (dateTime) {
    var complete_dateTime = new Date(dateTime);
    var tmp_dateTime = new Date(new Date(complete_dateTime.setDate(complete_dateTime.getDate() - 1)).setHours(23, 59, 59));
    var expiration_dateTime = makeDatetime(tmp_dateTime);
    return expiration_dateTime;
};
export var makePreviewModalDateTime = function () {
    var previewModaldateTime = '';
    var obj_date = new Date();
    var year = obj_date.getFullYear();
    var month = toStringMonthOrDate(obj_date.getMonth() + 1);
    var day = toStringMonthOrDate(obj_date.getDate());
    previewModaldateTime = "".concat(year, "-").concat(month, "-").concat(day, " 10:00");
    return previewModaldateTime;
};
export var toStringMonthOrDate = function (monthOrDate) {
    var string_monthOrDate = String(monthOrDate);
    if (string_monthOrDate.length === 1) {
        string_monthOrDate = "0".concat(string_monthOrDate);
    }
    return string_monthOrDate;
};
export var retryLazy = function (componentImport) {
    return lazy(function () { return __awaiter(void 0, void 0, void 0, function () {
        var pageAlreadyRefreshed, component, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    pageAlreadyRefreshed = JSON.parse(window.localStorage.getItem('pageRefreshed') || 'false');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, componentImport()];
                case 2:
                    component = _a.sent();
                    window.localStorage.setItem('pageRefreshed', 'false');
                    // console.log('success');
                    return [2 /*return*/, component];
                case 3:
                    error_1 = _a.sent();
                    console.log('failed', error_1);
                    if (!pageAlreadyRefreshed) {
                        window.removeEventListener('beforeunload', beforeUnLoadFunction);
                        window.localStorage.setItem('pageRefreshed', 'true');
                        return [2 /*return*/, window.location.reload()];
                    }
                    throw error_1;
                case 4: return [2 /*return*/];
            }
        });
    }); });
};
export var checkBrowser = function () {
    var agent = window.navigator.userAgent.toLowerCase();
    var browserName;
    switch (true) {
        case agent.indexOf('edge') > -1:
            browserName = 'MS Edge'; // MS 엣지
            break;
        case agent.indexOf('edg/') > -1:
            browserName = 'Edge (chromium based)'; // 크롬 기반 엣지
            break;
        case agent.indexOf('opr') > -1 && !!window.opr:
            browserName = 'Opera'; // 오페라
            break;
        case agent.indexOf('chrome') > -1 && !!window.chrome:
            browserName = 'Chrome'; // 크롬
            break;
        case agent.indexOf('trident') > -1:
            browserName = 'MS IE'; // 익스플로러
            break;
        case agent.indexOf('firefox') > -1:
            browserName = 'Mozilla Firefox'; // 파이어 폭스
            break;
        case agent.indexOf('safari') > -1:
            browserName = 'Safari'; // 사파리
            break;
        default:
            browserName = 'other'; // 기타
    }
    return browserName;
};
export var printIframe = function (print_iframe) {
    var browserName = checkBrowser();
    if (browserName === 'Safari') {
        // 사파리 예외 처리
        var printSafari = function () {
            var _a, _b;
            var popup = window.open('', '_blank', 'width=1,height=1');
            if (popup && print_iframe) {
                popup === null || popup === void 0 ? void 0 : popup.addEventListener('afterprint', function () {
                    popup.close();
                });
                popup.document.head.innerHTML = (_a = print_iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.document.head.innerHTML;
                popup.document.body.innerHTML = (_b = print_iframe.contentWindow) === null || _b === void 0 ? void 0 : _b.document.body.innerHTML;
            }
            setTimeout(function () {
                popup === null || popup === void 0 ? void 0 : popup.print();
                setTimeout(function () {
                    popup === null || popup === void 0 ? void 0 : popup.close();
                }, 200);
            }, 200);
        };
        printSafari();
    }
    else {
        setTimeout(function () {
            var _a;
            (_a = print_iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.print();
            var body = document.getElementsByTagName('body')[0];
            body.removeChild(print_iframe);
        }, 1000);
    }
};
export var makeChartResponsivePrintStyle = function (pieLayoutOtions) {
    var pie = pieLayoutOtions.pie, cell = pieLayoutOtions.cell, area = pieLayoutOtions.area;
    var pie_top = pie.top, pie_scale = pie.scale, pie_left = pie.left;
    var cell_top = cell.top, cell_scale = cell.scale, cell_left = cell.left;
    var area_paddingTop = area.paddingTop, area_marginBottom = area.marginBottom;
    var pieChartPrintStyle = "\n    #analyze_wrap > div:first-child > div:last-child > div > div:first-child {\n        top: ".concat(pie_top, "% !important;\n        transform: scale(").concat(pie_scale, ") !important;\n        left: ").concat(pie_left, "% !important;\n      }\n    #analyze_wrap > div:first-child > div:last-child > div > div:last-child {\n      top: ").concat(cell_top, "% !important;\n      transform: scale(").concat(cell_scale, ") !important;\n      left: ").concat(cell_left, "% !important;\n    }\n    #analyze_wrap > div:nth-child(3),\n    #analyze_wrap > div:last-child {\n      padding-top: ").concat(area_paddingTop, "px;\n    }\n    #analyze_wrap > div:nth-child(3) > div:first-child,\n    #analyze_wrap > div:last-child > div:first-child {\n      margin-bottom: ").concat(area_marginBottom, "px;\n    }\n  ");
    return pieChartPrintStyle;
};
export var setResponsiveOptions = function (viewport) {
    var originalResponsiveOptions = {
        pie: {
            top: 15,
            scale: 0,
            left: 0,
        },
        cell: {
            top: 0,
            scale: 0,
            left: 0,
        },
        area: {
            paddingTop: 13,
            marginBottom: -6,
        },
    };
    switch (true) {
        case viewport > 1904:
            originalResponsiveOptions.pie.scale = 1.15;
            originalResponsiveOptions.pie.left = 16;
            originalResponsiveOptions.cell.top = 13;
            originalResponsiveOptions.cell.scale = 1.05;
            originalResponsiveOptions.cell.left = 17.75;
            break;
        case viewport > 1770:
            originalResponsiveOptions.pie.scale = 1.35;
            originalResponsiveOptions.pie.left = 16.5;
            originalResponsiveOptions.cell.top = 13;
            originalResponsiveOptions.cell.scale = 1.05;
            originalResponsiveOptions.cell.left = 17.75;
            break;
        case viewport > 1574:
            originalResponsiveOptions.pie.scale = 1.35;
            originalResponsiveOptions.pie.left = 11.5;
            originalResponsiveOptions.cell.top = 13;
            originalResponsiveOptions.cell.scale = 1.05;
            originalResponsiveOptions.cell.left = 17;
            break;
        case viewport > 1383:
            originalResponsiveOptions.pie.scale = 1.35;
            originalResponsiveOptions.pie.left = 9.5;
            originalResponsiveOptions.cell.top = 14;
            originalResponsiveOptions.cell.scale = 1.25;
            originalResponsiveOptions.cell.left = 21;
            break;
        case viewport > 1197:
            originalResponsiveOptions.pie.scale = 1.65;
            originalResponsiveOptions.pie.left = 9.125;
            originalResponsiveOptions.cell.top = 14;
            originalResponsiveOptions.cell.scale = 1.4;
            originalResponsiveOptions.cell.left = 22;
            break;
        case viewport > 767:
            originalResponsiveOptions.pie.scale = 1.65;
            originalResponsiveOptions.pie.left = 8.85;
            originalResponsiveOptions.cell.top = 14;
            originalResponsiveOptions.cell.scale = 1.475;
            originalResponsiveOptions.cell.left = 24.25;
            break;
        default:
            originalResponsiveOptions.pie.scale = 1.65;
            originalResponsiveOptions.pie.left = 10.5;
            originalResponsiveOptions.cell.top = 14;
            originalResponsiveOptions.cell.scale = 1.475;
            originalResponsiveOptions.cell.left = 24.5;
            break;
    }
    var browserName = checkBrowser();
    if (browserName === 'Safari') {
        originalResponsiveOptions.pie.top -= 12;
        originalResponsiveOptions.pie.scale -= 0.1;
        originalResponsiveOptions.cell.top -= 12;
        originalResponsiveOptions.cell.left += 1;
        originalResponsiveOptions.area.paddingTop -= 5;
        originalResponsiveOptions.area.marginBottom -= 9;
    }
    return originalResponsiveOptions;
};
export var escapeSpecialCharacter = function (keyword) {
    var encode_keyword = encodeURIComponent(keyword);
    return encode_keyword;
};
export var processingPaymentState = function (state, kind, deleted_date, called) {
    var label = state;
    if (state === '결제대기') {
        return { type: 'content', label: label };
    }
    else if (state === '결제완료') {
        if (['카드', '간편결제'].includes("".concat(kind)) || (called && ['manage', 'statistics'].includes(called))) {
            return { type: 'content', label: label };
        }
        else {
            return { type: 'color #2c7cd8', label: label };
        }
    }
    else if (state === '결제취소') {
        return { type: 'color red', label: label };
    }
    else if (state === '청구취소') {
        return { type: 'color #e1e2e3', label: label };
    }
    else if (state === '납부만료') {
        return { type: 'content', label: '납부만료' };
    }
    else {
        if (deleted_date && deleted_date != '1001-01-01 00:00:00') {
            return { type: 'color #e1e2e3', label: '청구취소' };
        }
        else {
            return { type: 'color #e1e2e3', label: '청구대기' };
        }
    }
};
export var processingPaymentDate = function (state, date, isDate) {
    if (date) {
        var label = date;
        if (isDate) {
            label = label.split(' ')[0];
        }
        if (state === '결제대기') {
            return { type: 'color black', label: label };
        }
        else if (state === '결제완료') {
            return { type: 'color #2c7cd8', label: label };
        }
        else if (state === '결제취소') {
            return { type: 'color red', label: label };
        }
        else if (state === '청구취소') {
            return { type: 'color #e1e2e3', label: label };
        }
        else if (state === '청구대기') {
            return { type: 'color #e1e2e3', label: label };
        }
        else if (state === '청구삭제') {
            return { type: 'color #e1e2e3', label: label };
        }
        else if (state === '납부만료') {
            return { type: 'color #e1e2e3', label: label };
        }
    }
    else {
        return '-';
    }
};
export var makeImmutabilityCreateDetail = function (obj) {
    var return_obj = {};
    var obj_keys = Object.keys(obj);
    for (var i = 0; i < obj_keys.length; i++) {
        var key = obj_keys[i];
        return_obj[key] = __assign({}, obj[key]);
    }
    return return_obj;
};
export var changeNoticeDateTimeToDate = function (dateTime) {
    if (dateTime) {
        // dateTime이 없으면 실행 x
        var no_dots_date_time = dateTime.replace(/\./g, '');
        var date_time_array = no_dots_date_time.split(' ');
        date_time_array.pop();
        var date_time_string = date_time_array.join('-');
        return date_time_string;
    }
    else {
        return '-';
    }
};
export var paymentDateToDateType = function (paymentDate) {
    var date_array = paymentDate.split('. ');
    return date_array.join('/');
};
export var getDueDate = function (amountDay, period) {
    var number_period = Number(period);
    var amount_day = amountDay.replace(/-/g, '/');
    var notice_date = new Date(amount_day);
    var due_date = new Date(notice_date.setDate(notice_date.getDate() + number_period));
    return setLocaleDateString(due_date, '-');
};
export var setDomTitle = function (className) {
    var label_array = Array.from(document.getElementsByClassName(className));
    for (var i = 0; i < label_array.length; i++) {
        var label = label_array[i];
        if (label.offsetWidth < label.scrollWidth) {
            var title = label.dataset.label || '';
            label.setAttribute('title', title);
        }
        else {
            label.setAttribute('title', '');
        }
    }
};
export var makeErrorTextAndPlaceholder = function (type) {
    var type_obj = {
        phone: {
            errorText: '올바른 휴대폰 번호를 입력해주세요.',
            placeholder: '번호를 입력하세요.',
        },
    };
    return type_obj[type];
};
export var changeNumberToWon = function (price) {
    return '₩ ' + makeCommaAsThousandSeparator(price) + '원';
};
export var checkLeapYear = function (year) {
    return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
};
export var makeAutoBillingDate = function (day, originDay, account, thisMonthSend) {
    var now = new Date();
    var today = now.getDate();
    var this_year = now.getFullYear();
    var this_month = now.getMonth();
    var next_month = this_month + 1;
    var this_month_last_day = dayjs(now).endOf('month').date();
    var next_month_last_day = dayjs(now).add(1, 'month').endOf('month').date();
    var lastDay;
    var minus_cnt = 0;
    var auto_billing_month;
    var auto_billing_day = day;
    var compare_origin_day_with_today = '';
    if (originDay) {
        if (account) {
            compare_origin_day_with_today = 'after';
        }
        else {
            compare_origin_day_with_today = compareOriginDayWithToday(today, originDay);
        }
    }
    if (day < today ||
        compare_origin_day_with_today === 'before' ||
        (compare_origin_day_with_today === 'today' && !account) ||
        thisMonthSend) {
        // ( 당일 포함 ) 이전일인 경우
        // 다음달이 2월인지 아닌지 여부 확인
        if (next_month === 1) {
            // 윤년 확인
            if (checkLeapYear(this_year)) {
                lastDay = 29;
                minus_cnt = 3;
            }
            else {
                lastDay = 28;
                minus_cnt = 4;
            }
            if (day > lastDay) {
                auto_billing_day = lastDay;
            }
        }
        else {
            lastDay = new Date(new Date(new Date().setMonth(new Date().getMonth() + 2)).setDate(0)).getDate();
            if (day > lastDay) {
                auto_billing_day = next_month_last_day;
            }
        }
        auto_billing_month = next_month;
    }
    else {
        // ( 당일 제외 ) 이후일인 경우
        // 이번달이 2월인지 아닌지 여부 확인
        if (this_month === 1) {
            // 윤년 확인
            if (checkLeapYear(this_year)) {
                lastDay = 29;
                minus_cnt = 3;
            }
            else {
                lastDay = 28;
                minus_cnt = 4;
            }
            if (day > lastDay) {
                auto_billing_day = lastDay;
            }
        }
        else {
            lastDay = new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)).getDate();
            if (day > lastDay) {
                if (today === lastDay) {
                    lastDay = new Date(new Date(new Date().setMonth(new Date().getMonth() + 2)).setDate(0)).getDate();
                }
                if (day > lastDay) {
                    lastDay = this_month_last_day;
                }
                auto_billing_day = lastDay;
            }
        }
        auto_billing_month = this_month;
    }
    var auto_billing_date = '';
    if (next_month === 1) {
        var pastDate = new Date(new Date().setDate(new Date().getDate() - minus_cnt));
        auto_billing_date = setLocaleDateString(new Date(new Date(pastDate.setMonth(auto_billing_month)).setDate(auto_billing_day)), 'kor');
    }
    else {
        auto_billing_date = setLocaleDateString(new Date(new Date(new Date().setDate(auto_billing_day)).setMonth(auto_billing_month)), 'kor');
    }
    var return_label = "///( \uC790\uB3D9 \uCCAD\uAD6C\uC77C : ".concat(auto_billing_date, " )");
    return return_label;
};
var compareOriginDayWithToday = function (today, originDay) {
    var return_string;
    if (today > originDay) {
        return_string = 'before';
    }
    else if (today === originDay) {
        return_string = 'today';
    }
    else {
        return_string = 'after';
    }
    return return_string;
};
export var templateButtonString = function (op) {
    switch (op) {
        case 'academy':
            return '{학원명}';
        case 'name':
            return '{이름}';
        case 'student_name':
            return '{학생명}';
        case 'notice_date':
            return '{청구일}';
        case 'class':
            return '{클래스}';
        case 'end_date':
            return '{납부기한}';
        case 'amount':
            return '{청구금액}';
        case 'phone':
            return '{발신번호}';
        default:
            return '';
    }
};
export var templateButtonJumpCursor = function (op) {
    switch (op) {
        case 'name':
            return 4;
        case 'academy':
            return 5;
        case 'student_name':
            return 5;
        case 'notice_date':
            return 5;
        case 'class':
            return 5;
        case 'end_date':
            return 6;
        case 'amount':
            return 6;
        case 'phone':
            return 6;
        default:
            return 0;
    }
};
export function processingMaxOrMin(maxOrMin, type) {
    var variation = type === 'max' ? 1 : -1;
    var number_length = String(maxOrMin).length;
    var new_max_or_min;
    if (type === 'max') {
        new_max_or_min = Math.floor(maxOrMin / Math.pow(10, number_length - 1));
    }
    else {
        new_max_or_min = Math.ceil(maxOrMin / Math.pow(10, number_length - 1));
    }
    // 0이 있을경우를 위한 예외처리
    if (new_max_or_min === 0) {
        return new_max_or_min;
    }
    new_max_or_min += variation;
    if (new_max_or_min === 0) {
        // 1000만, 100만은 무조건 0만으로 나오는 예외 때문에 하는 처리
        new_max_or_min = 9;
        number_length--;
    }
    new_max_or_min = new_max_or_min * Math.pow(10, number_length - 1);
    return new_max_or_min;
}
export var makeStatusColor = function (status) {
    switch (status) {
        case '결제완료':
            return '#2c7cd8';
            return;
            break;
        case '결제취소':
            return '#e9121d';
            break;
        case '결제대기':
            break;
        default:
            return '#e1e2e3';
            break;
    }
};
export var makeCalculateExcelKeysAndStyles2 = function (type) {
    if (type === 'list') {
        return {
            excel_keys: {
                no: '번호',
                payment_kind_origin: '결제수단',
                payment_date: '결제일',
                adjustment_date: '정산일',
                payment_amount: '결제금액',
                total_fee: '수수료',
                adjustment_amount: '정산금액',
                adjustment_result: '정산 여부',
                memo: '비고',
            },
            excel_styles: {
                no: { width: 8, center: true },
                payment_kind_origin: { width: 18, center: true },
                payment_date: { width: 18, center: true },
                adjustment_date: { width: 18, center: true },
                payment_amount: { width: 18, center: true },
                total_fee: { width: 18, center: true },
                adjustment_amount: { width: 18, center: true },
                adjustment_result: { width: 18, center: true },
                memo: { width: 67, center: true },
            },
        };
    }
    else {
        return {
            excel_keys: {
                no: '번호',
                complete_settle_type: '결제수단',
                rname: '클래스',
                uname: '이름',
                complete_date_time: '결제일',
                refund_date_time: '취소일',
                amount: '청구금액',
                pay_amount: '결제금액',
                memo: '비고',
            },
            excel_styles: {
                no: { width: 8, center: true },
                complete_settle_type: { width: 18, center: true },
                rname: { width: 30, center: true },
                uname: { width: 18, center: true },
                complete_date_time: { width: 18, center: true },
                refund_date_time: { width: 18, center: true },
                amount: { width: 18, center: true },
                pay_amount: { width: 18, center: true },
                memo: { width: 67, center: true },
            },
        };
    }
};
export var makeCalculateExcelKeysAndStyles = function () {
    return {
        excel_keys: {
            no: '번호',
            payment_kind_origin: '결제수단',
            payment_date: '결제일',
            adjustment_date: '정산일',
            payment_amount: '결제금액',
            total_fee: '수수료',
            adjustment_amount: '정산금액',
            adjustment_result: '정산 여부',
            memo: '비고',
        },
        excel_styles: {
            no: { width: 8, center: true },
            payment_kind_origin: { width: 18, center: true },
            payment_date: { width: 38, center: true },
            adjustment_date: { width: 18, center: true },
            payment_amount: { width: 18, center: true, numFmt: '"￦ "#,##0' },
            total_fee: { width: 18, center: true, numFmt: '"￦ "#,##0' },
            adjustment_amount: { width: 18, center: true, numFmt: '"￦ "#,##0' },
            adjustment_result: { width: 18, center: true },
            memo: { width: 67, center: true },
        },
    };
};
export var isKoreanCharacter = function (char) {
    return /[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(char);
};
export var isSpaceCharacter = function (char) {
    return /^\s*$/g.test(char);
};
// * 링크 속하는지 비교
// * 맨 마지막 회차에 한글포함되어 있으면 안내문구 띄어주기
export var isIncludeLink = function (text) {
    var strArr = text.split(/\s+/);
    var urlCnt = 0;
    var linkIndex = 0;
    for (var i = 0; i < strArr.length; i++) {
        var value = strArr[i];
        // * 1. 한글지우기
        var withoutKorean = value.replace(/[ㄱ-ㅎㅏ-ㅣ가-힣]/g, ' ');
        // * 2. 앞뒤 공백 지우기
        var withoutSpace = withoutKorean.replace(/^\s*/, '');
        var withdoutSc = withoutSpace.replace(/\s.*$/, '');
        // * 3. 앞뒤 특수문자 지우기
        var trimmedString = withdoutSc.replace(/[^a-zA-Z0-9:/.]/g, '');
        var pattern = /^(https?:\/\/)?([\w\d-]+\.)+[\w]{2,}(\/[\w\d-._~:/?#[\]@!$&'()*+,;=%]*)?$/i;
        if (pattern.test(trimmedString)) {
            var isNumericWithDotsOnly = /^[\d.]+$/.test(trimmedString);
            if (!isNumericWithDotsOnly) {
                linkIndex = i;
                urlCnt++;
            }
        }
    }
    var isIncludeString = false;
    if (urlCnt > 0) {
        var target_str = strArr[linkIndex];
        isIncludeString = !/[a-zA-Z]/.test(target_str[target_str.length - 1]);
    }
    return isIncludeString;
};
// 각 날짜를 Date 객체로 변환하고 필터링
export var filteredMonth = function (holidays, target_month) {
    return holidays.filter(function (dateString) {
        var date = new Date(dateString);
        var month = date.getMonth() + 1;
        return month === target_month;
    });
};
// * 해당하는 년/월 알림뱅킹 해지일자 구하기
export var getTerminateDate = function (year, month) { return __awaiter(void 0, void 0, void 0, function () {
    var url;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "/management/getAlimTerminateDate/".concat(year, "/").concat(month);
                return [4 /*yield*/, fetchGetApi(url)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getIsHoliday = function (year) { return __awaiter(void 0, void 0, void 0, function () {
    var url;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "{ims}/common/getHoliday/".concat(year, "/noMonth");
                return [4 /*yield*/, fetchGetApi(url)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
var isWeekend = function (date) {
    var day = date.getDay();
    return day === 0 || day === 6;
};
// * 영업일 구하기
export var getBusinessDay = function (date, holidays) {
    var currentDate = new Date(date);
    while (isWeekend(currentDate) || holidays.includes(setLocaleDateString(currentDate, '-'))) {
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return currentDate;
};
export var getDefaultSortingTableQuery = function (_a) {
    var tableSort = _a.tableSort, table_query_key = _a.table_query_key;
    if (tableSort &&
        tableSort[table_query_key] &&
        tableSort[table_query_key].sortKey &&
        tableSort[table_query_key].sortValue) {
        return {
            target: tableSort[table_query_key].sortKey,
            sort: tableSort[table_query_key].sortValue,
            page: 1,
            max: getListNum(),
        };
    }
    else {
        var default_sort = TableDefaultSortKey[table_query_key];
        return {
            target: default_sort.sortKey,
            sort: default_sort.sortValue,
            page: 1,
            max: getListNum(),
        };
    }
};
export var sortingTableWithTableQueryKey = function (_a) {
    var tableSort = _a.tableSort, table_query_key = _a.table_query_key, setTableQuery = _a.setTableQuery, sortKey = _a.sortKey, sortValue = _a.sortValue;
    if (tableSort &&
        tableSort[table_query_key] &&
        tableSort[table_query_key].sortKey &&
        tableSort[table_query_key].sortValue) {
        setTableQuery({
            target: tableSort[table_query_key].sortKey,
            sort: tableSort[table_query_key].sortValue,
            page: 1,
            max: getListNum(),
        });
    }
    else {
        var default_sort = TableDefaultSortKey[table_query_key];
        setTableQuery({
            target: sortKey ? sortKey : default_sort.sortKey,
            sort: sortValue ? sortValue : default_sort.sortValue,
            page: 1,
            max: getListNum(),
        });
    }
};
// * 12시간제로 변경
export var format12times = function (time) {
    var _a = time.split(':'), hours = _a[0], minutes = _a[1];
    var hoursInt = parseInt(hours, 10);
    var amOrPm = hoursInt >= 12 ? 'PM' : 'AM';
    hoursInt = hoursInt % 12;
    hoursInt = hoursInt ? hoursInt : 12; // 0시는 12시로 변환
    var formattedHours = hoursInt.toString().padStart(2, '0');
    return "".concat(formattedHours, ":").concat(minutes, " ").concat(amOrPm);
};
// * YYYYMMDDHHMMSS 형식으로 변경
export var formatReserveTime = function (date) {
    return dayjs(date).format('YYYYMMDDHHmmss');
};
// * 10분 체크 하여 10분 이전일 경우 미래로 변환
export var check10minutes = function (date, format) {
    var now = dayjs();
    var target = dayjs(date);
    if (target.diff(now, 'minute') <= 10) {
        var adjustedTime = now.add(10, 'minute');
        var minutes = adjustedTime.minute();
        var roundedMinutes = 5 * Math.round(minutes / 5);
        adjustedTime = adjustedTime.minute(roundedMinutes).second(0);
        return adjustedTime.format(format);
    }
    else {
        return date;
    }
};
export var changeCalendarWeekdayName = function (idx) {
    switch (idx) {
        case 0:
            return 'Sun';
        case 1:
            return 'Mon';
        case 2:
            return 'Tue';
        case 3:
            return 'Wed';
        case 4:
            return 'Thu';
        case 5:
            return 'Fri';
        case 6:
            return 'Sat';
        default:
            return '';
    }
};
export var diffDay = function (startdate, enddate) {
    var start_dayjs = dayjs(startdate);
    var end_dayjs = dayjs(enddate);
    var duration = end_dayjs.diff(start_dayjs, 'days');
    return duration;
};
