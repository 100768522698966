var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useSelector } from 'react-redux';
import { POPUP_BASE_URL } from './constants';
export function getKorUclass(uclass) {
    var res = { type: '', label: '' };
    switch (uclass) {
        case 'B':
            res = { type: 'color #FF65BD', label: '대표 관리자' };
            break;
        case 'VP':
            res = { type: 'color #2b8ad1', label: '부원장' };
            break;
        case 'CO':
            res = { type: 'color #2b8ad1', label: '실장' };
            break;
        case 'T':
            res = { type: 'color #2b8ad1', label: '선생님' };
            break;
        case 'ETC':
            res = { type: 'color #2b8ad1', label: '기타' };
            break;
        case 'S':
            res = { type: 'color #2b8ad1', label: '재원생' };
            break;
        case 'SB':
            res = { type: 'color #2b8ad1', label: '휴원생' };
            break;
        case 'SX':
            res = { type: 'color #2b8ad1', label: '퇴원생' };
            break;
        case 'SN':
            res = { type: 'color #2b8ad1', label: '미등록생' };
            break;
    }
    return res;
}
export function getKorUPCheck(upcheck, type) {
    var upcheck_list = ['', '어머니', '아버지', '학생'];
    var upcheck_arr = upcheck.split(',').filter(function (item) { return item; });
    var res = upcheck_arr.map(function (item) { return upcheck_list[Number(item)]; }).join(', ');
    return res.length > 0 ? res : type === 'account' ? '직접 입력' : '전체 미발송';
}
export function getKeyUPCheck(upcheck) {
    var upcheck_list = ['uphone', 'ufaphone', 'umyphone'];
    var upcheck_arr = upcheck.split(',').filter(function (item) { return item; });
    var res = upcheck_arr.map(function (item) { return upcheck_list[Number(item) - 1]; });
    return res;
}
export function getStudentUYear(res_target, target) {
    var comparison = res_target == 'id' ? 'name' : 'id';
    var contents = [
        { id: 'e1', name: '초등학교 1학년' },
        { id: 'e2', name: '초등학교 2학년' },
        { id: 'e3', name: '초등학교 3학년' },
        { id: 'e4', name: '초등학교 4학년' },
        { id: 'e5', name: '초등학교 5학년' },
        { id: 'e6', name: '초등학교 6학년' },
        { id: 'm1', name: '중학교 1학년' },
        { id: 'm2', name: '중학교 2학년' },
        { id: 'm3', name: '중학교 3학년' },
        { id: 'h1', name: '고등학교 1학년' },
        { id: 'h2', name: '고등학교 2학년' },
        { id: 'h3', name: '고등학교 3학년' },
        { id: 'p4', name: '4세' },
        { id: 'p5', name: '5세' },
        { id: 'p6', name: '6세' },
        { id: 'p7', name: '7세' },
    ];
    var res = contents.find(function (item) {
        return item[comparison] == target;
    });
    if (res) {
        return res[res_target];
    }
    else {
        return '';
    }
}
export var student_grade_list = [
    { id: 'e1', name: '초등학교 1학년' },
    { id: 'e2', name: '초등학교 2학년' },
    { id: 'e3', name: '초등학교 3학년' },
    { id: 'e4', name: '초등학교 4학년' },
    { id: 'e5', name: '초등학교 5학년' },
    { id: 'e6', name: '초등학교 6학년' },
    { id: 'm1', name: '중학교 1학년' },
    { id: 'm2', name: '중학교 2학년' },
    { id: 'm3', name: '중학교 3학년' },
    { id: 'h1', name: '고등학교 1학년' },
    { id: 'h2', name: '고등학교 2학년' },
    { id: 'h3', name: '고등학교 3학년' },
    { id: 'etc', name: '기타' },
    { id: 'p4', name: '4세' },
    { id: 'p5', name: '5세' },
    { id: 'p6', name: '6세' },
    { id: 'p7', name: '7세' },
];
export var student_upcheck_list = [
    { id: '1', name: '어머니' },
    { id: '2', name: '아버지' },
    { id: '3', name: '학생' },
    { id: 'all_no', name: '전체 미발송' },
];
export var getUserInfo = function () {
    var user_info = useSelector(function (state) { return state.UserReducer; });
    return user_info;
};
export var sendWithinquiresMail = function (inquries) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetch("".concat(POPUP_BASE_URL, "/homepage/sendemail.php"), {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                    },
                    body: JSON.stringify(__assign({}, inquries)),
                })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var sendWithdrawalMail = function (withdraw_datas) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetch("".concat(POPUP_BASE_URL, "/engtrain/sendwithdrawal"), {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(__assign({}, withdraw_datas)),
                })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var createAccount = function (require_datas) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetch("".concat(POPUP_BASE_URL, "/engtrain/settingadmininfoV2"), {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(__assign({}, require_datas)),
                })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getUpCheckLength = function (upcheck) {
    return upcheck.split(',').length;
};
export var getTargetRepresentative = function (upcheck) {
    var representative_target = '';
    var representative_target_phone_key = 'phone';
    return { representative_target: representative_target, representative_target_phone_key: representative_target_phone_key };
};
export var insertLog = function (user, action) { return __awaiter(void 0, void 0, void 0, function () {
    var params, url, response, responseJson;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(process.env.NODE_ENV === 'production')) return [3 /*break*/, 3];
                params = {
                    service: 'checkbus_v2',
                    cid: user.cid,
                    uid: user.uid,
                    uname: user.uname,
                    uloginid: user.uloginid,
                    uclass: user.uclass,
                    action: action,
                };
                url = process.env.NODE_ENV === 'production' ? 'https://www.checkbus.co.kr' : 'http://49.236.146.241';
                return [4 /*yield*/, fetch("".concat(url, "/irontrain/log/add"), {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(__assign({}, params)),
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2:
                responseJson = _a.sent();
                _a.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); };
